import React from 'react'
import SEO from '../components/seo'
import ComponentParser from '../components/ComponentParser'

const Page = (props) => {
  return (
    <>
      <SEO data={props.pageContext.seo} />
        <div className="page-content">
          <ComponentParser content={props.pageContext.blocks} />
        </div>
    </>
  )
}

export default Page